import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { ToastsBasic } from "../../examples/components/Toasts";
export const query = graphql`
  query ToastQuery {
    Toast: componentMetadata(displayName: { eq: "Toast" }) {
      ...ComponentApi_metadata
    }
    ToastHeader: componentMetadata(displayName: { eq: "ToastHeader" }) {
      ...ComponentApi_metadata
    }
    ToastBody: componentMetadata(displayName: { eq: "ToastBody" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Toasts are available for any length of text, as well as an optional dismiss
  button. For proper styling, use one of the eight required contextual classes
  (e.g., .<code>toast-success</code>). For inline dismissal, use the prop{" "}
  <code>show</code> to set the visibility of the toast.
    </Overview>
    <CodeBlock title="Toasts" code={ToastsBasic} mdxType="CodeBlock"></CodeBlock>
    <LinkedHeading h="2" id="toasts-api" className="h1" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Toast} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToastHeader} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ToastBody} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      